<template>
  <div>
    <a-modal
      v-model="visible"
      :title="$t('产品选择')"
      width="960px"
      :destroyOnClose="true"
      @cancel="onCloseModel"
      @ok="onConfirm"
    >
      <a-row gutter="16">
        <a-col :span="24" style="width: 256px">
          <a-input-search v-model="searchForm.search" :placeholder="$t('产品名称, 编码, 批次')" allowClear @search="search" />
        </a-col>
      </a-row>

      <div style="margin-top: 12px">
        <a-table
          :data-source="items"
          size="small"
          rowKey="id"
          :columns="columns"
          :loading="loading"
          :pagination="pagination"
          :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          @change="onChangeTable"
        >
          <div slot="action" slot-scope="value, item, index">
            <a-button size="small" @click="select(item)">{{ $t("选择") }}</a-button>
          </div>
        </a-table>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { batchOption } from "@/api/option";
import { columns } from "./columns";

export default {
  props: ["visible", "warehouse", "client"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      columns,
      pagination: {},
      searchForm: {},
      loading: false,
      items: [],
      selectedRowKeys: [],
    };
  },
  methods: {
    onCloseModel() {
      this.$emit("cancel", false);
    },
    onChangeTable(pagination, filters, sorter) {
      this.pagination = pagination;
      this.searchForm.page = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    search() {
      this.pagination.current = 1;
      this.searchForm.page = 1;
      this.list();
    },
    list() {
      this.loading = true;
      let searchForm = { warehouse: this.warehouse, client: this.client, ...this.searchForm };
      batchOption(searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    select(item) {
      this.$emit("select", item);
      this.onCloseModel();
    },
    resetModel() {
      this.pagination = {
        current: 1,
        total: 0,
        pageSize: 16,
        showTotal: (total) => `${this.$t("共")} ${total} ${this.$t("条")}`,
      };
      this.searchForm = { page: 1 };
      this.items = [];
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    onConfirm() {
      const dataItems = [];
      for (let selectedRowKeys of this.selectedRowKeys) {
        for (const item of this.items) {
          if (selectedRowKeys == item.id) {
            dataItems.push(item);
            break;
          }
        }
      }

      this.$emit("confirm", dataItems);
      this.onCloseModel();
    },
  },
  watch: {
    visible(status) {
      if (status) {
        this.resetModel();
        this.list();
      }
    },
  },
};
</script>
